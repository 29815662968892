
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.row {
  display: flex;
  justify-content: center;
}

header {
  margin-top: 20px;
}

h1, h2 {
  color: #343a40;
}

footer {
  margin-bottom: 20px;
}

a {
  padding: 15px;
  background-color: transparent;
  border-radius: .35rem;
  font-size: 1.3rem;
  color: #495057;
  text-decoration: none;
}

a:hover {
  background-color: #e1e6ed;
}