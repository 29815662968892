@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100vh;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

header {
  margin-top: 20px;
}

h1, h2 {
  color: #343a40;
}

footer {
  margin-bottom: 20px;
}

a {
  padding: 15px;
  background-color: transparent;
  border-radius: .35rem;
  font-size: 1.3rem;
  color: #495057;
  text-decoration: none;
}

a:hover {
  background-color: #e1e6ed;
}
